@import "variable";
@import "media";
@import "mixins";

@font-face {
    font-family: 'HelveticaNeue-Regular';
    src: url(../fonts/HelveticaNeue-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue-Medium';
    src: url(../fonts/HelveticaNeue-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: url(../fonts/HelveticaNeue-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'HelveticaNeue-Light';
  src: url(../fonts/HelveticaNeue-Light.otf) format('opentype');
}


body {
  padding: 0;
  margin: 0;
  font-size: $base-font-size; 
  font-family: $font-regular;
  h1 {
  	font-size: 22px;
  	color: $base-color;
  	font-family: $font-medium;
  	margin-bottom: 35px;
  }

  h3 {
  	font-size: 18px;
  	color: $base-color;
  	font-family: $font-medium;
  }
}

.container {
max-width: 100%;
padding: 30px;
}

.font-medium {
	font-family: $font-medium !important;
}

.font-bold {
	font-family: $font-bold !important;
}

.font-light-300 {
	font-family: $font-light !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.pr-0 {
	padding-right: 0;
}

.pb-10 {
	padding-bottom: 10px !important;
}

.pl-15 {
	padding-left: 15px !important; 
}

.mt-35 {
	margin-top: $top-spacing;
}

.mt-20 {
	margin-top: $top-20;
}

.mr-0 {
	margin-right: 0 !important;
}

.text-right {
	text-align: $text-right;
}

.pr-20 {
	padding-right: 20px !important;
}

.add-new-btn {
	background-color: #3c68c3;
	border-color: #3c68c3;
}

.confirm-btn {
	background-color: #3c68c3;
	border-color: #3c68c3;
	color: #fff;
	max-width: 122px;
	width: 100%;
	font-family: $font-medium;
	padding: 7px 10px;
}

.edit-btn {
	background-color: transparent;
	border-color: #3c68c3;
	color: #3c68c3;
	padding: 7px 10px;
	max-width: 122px;
	width: 100%;
	font-family: $font-medium;
}

.nav-inner-wrapper ul li a {
	font-family: "Roboto", sans-serif;
	font-weight: 400;
}

::-moz-placeholder {
	color: #373A3C !important;
	font-size: $base-font-size;
	opacity: .8 !important;
	font-family: $font-regular;
}

::-webkit-input-placeholder{
	color: #373A3C !important;
	opacity: .8 !important;
	font-size: $base-font-size;
	font-family: $font-regular;
}

:-ms-input-placeholder {
	color: #373A3C !important;
	opacity: .8 !important;
	font-size: $base-font-size;
	font-family: $font-regular;
}

::placeholder {
	color: #373A3C !important;
	opacity: 0.8 !important;
	font-size: $base-font-size;
	font-family: $font-regular;
}

input.form-control, 
select, 
textarea.form-control,
input,textarea {
padding: 7px 10px;
font-family: $font-regular;
color: #373A3C;
font-weight: normal;
outline: 0;
border-radius: 4px;
-moz-border-radius: 4px;
-webkit-border-radius: 4px;
opacity: 0.8;
}

input[type="radio"] {
	padding: 0 !important;
}

input.search {
	background: url('/assets/img/search-tc-icon.svg') no-repeat right 8px center;
	background-size: 23px auto;
	max-width: 280px;
	width: 100%;
}

input:focus, select:focus, textarea:focus {
	outline: 0 !important;
	box-shadow: inherit !important;
	-moz-box-shadow: inherit !important;
	-webkit-box-shadow: inherit !important;
}

table {
	border: none;
	margin-top: 10px;
	margin-bottom: 0 !important;

	thead {
		th {
			background-color:#fff !important;
		}
	}

	th,td {
		font-family: $font-medium;
		font-size: $base-font-size;
		color: #29304D;
		text-align: $text-left;
		white-space: nowrap;
	}

	tr:nth-of-type(2n+1) {
		background-color:rgba(241,242,245,0.5);
		-webkit-transition: $site-transition;
		-o-transition: $site-transition;
		transition: $site-transition;
	}

	tr:nth-of-type(2n) {
		background-color:#fff;
		-webkit-transition: $site-transition;
		-o-transition: $site-transition;
		transition: $site-transition;
	}

	tr:hover {
		background-color: #F1F2F5;
	}

	th {
		font-weight: normal;
		border-bottom: none;
		padding: 10px 8px !important;
	}

	td {
    font-family: $font-regular;
    padding: 10px 8px !important;
	}


}

header.site-header {
	.navbar-brand {
		margin-right: 30px;
	}

	.navbar-nav li.nav-item { 
		.audit-text {
			border-top-left-radius: 4px;
		  -moz-border-top-left-radius: 4px;
		  -webkit-border-top-left-radius: 4px;
		  border-bottom-left-radius: 4px;
		 -moz-border-bottom-left-radius: 4px;
		 -webkit-border-bottom-left-radius: 4px;
		}

		.react-datepicker__input-container input {
			max-width: 157px;
		}

		.dropdown-toggle {
			position: relative;
		}
	}
}

.search-form {
	box-shadow: $white-box-shadow;
	-moz-box-shadow: $white-box-shadow;
	-webkit-box-shadow: $white-box-shadow;
  background-color: $box-background;
  padding: $box-padding;
  margin-bottom: 20px;
  border-radius: $border-radius;
  -moz-border-radius: $border-radius;
  -webkit-border-radius: $border-radius;
  .btn.btn-primary {
  	margin-top: -1px;
  	max-width: 122px;
  	padding: 8px 15px;
  	text-align: center;
  	background-color: #C0C1C2;
  	color: #fff;
  	font-family: $font-medium;
  	border: none;
  	width: 100%;
  	display: block;
  }
}

.list-view-box {
  box-shadow: $white-box-shadow;
	-moz-box-shadow: $white-box-shadow;
	-webkit-box-shadow: $white-box-shadow;
  background-color: $box-background;
  margin-bottom: $box-margin;
  padding-top: 0;
  border-radius: $border-radius;
  -moz-border-radius: $border-radius;
  -webkit-border-radius: $border-radius;

  .search-heading {
  	padding: 15px;

  	.form-control {
  		box-shadow: none;
			margin-right: 89px;
  	}

  	.export-filter {
  		display: $display-flex;
			justify-content: flex-end;

			span {
				position: relative;
				top: 1px;
				left: -3px;
			}
  	}

  	.export-btn {
  		line-height: 2.3;
			color: #29304d;
			text-decoration: none;
			cursor: pointer;
			margin-right: 30px;
  	}

  	.export-btn img {
  		line-height: 2.1;
			font-size: 18px;
  	}

  	.filter-btn {
  		display: $display-flex;
			background-color: #ececed;
			border: 1px solid #DDDDDD;
			color: #29304d;

			img {
				margin-right: 7px;
				position: relative;
				top: 6px;
			}
  	}
  }
}

.pagination {
	display: $display-flex;
	padding-left: 0;
	list-style: none;

	input[type="text"] {
		width: 30px;
		height: 30px;
		-webkit-appearance:none;
		appearance:none;
		border:1px solid #ddd;
		border-radius: 4px;
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		margin-top: 4px;
	}

	.next-item {
		margin-right: 10px;
		a {
			background-color: transparent;
			color: #777 !important;
		}
	}

	.previous-item {
		margin-left: 15px;
		span {
			line-height: 2.4;
			font-size: 15px;
		}
	}

	.page-link.active {
		color: #3c68c3;
	}
}

.my-installation-content {
	.tc-audit-no-title {
		background: url('/assets/img/main-tab-arrow.svg') no-repeat right 10px center;
	}
}

.management-title {
	h1 {
		margin-bottom: 15px;
	}
}

.add-mr-content {
	.code-section {
		span {
			color: #797B84;
			font-family: 'HelveticaNeue-Regular';
		}
	}
}


.substation-list {
	.collapse-text[aria-expanded="true"] {
	  background-color: #F8F8FA66;
	}

	.card-body {
		table {
			tr {
				background-color: #fff !important;
			}
		}
	}
}

.main-tc-table.list-view-box {
	box-shadow: inherit;
	border-radius: inherit;
	padding-top: 0 !important;
}

.filter-bar {
	.filter-title {
		font-family: 'HelveticaNeue-Bold';
	}

	.mr-title {
		font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
	}

	.month-tab .tabs a {
		margin-bottom: 14px;

	}
}


.tc-audit-no-list {
	.tc-audit-no-title {
		background: none;
	}

	.number {
		color: #29304D;
		font-family: 'Roboto', sans-serif;
    font-weight: 500;
	}
}

.audit-list-box {
	.react-datepicker-wrapper {
		input {
			border-left: 1px solid #ced4da;
		}
	}
}

.add-tc-info {
	.code-section {
		margin-bottom: 15px;
	}

	span {
		color: #797B84;
	}

	.close-mr .btn {
		display: block !important;
		width: auto !important; 
	}
}

.transformer-detail-form {
	.energy-audit-view p.heading {
		font-family: $font-medium;
	}

	ul {
		li {
			list-style: none;
		}
	}
}

.dashboard-content .react-datepicker-popper {
top: -5px !important;
left: -110px !important;	
}